export default {
    colors: {
      primary: '#008b00',
      background: '#181718',
      shape: `#e6f5e5`,
      title: `#ababab`,
      text: `#E1E1E6`,
      components: {
        blockquote: {
          background: `#008b00`,
          text: `#E1E1E6`,
        },
      },
    },
  };